"use client";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { FaArrowUp } from "react-icons/fa";
export const dynamic = "force-dynamic";

const LiveMessanger = () => {
    const [isVisible, setisVisible] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };
    const handleButtonClick = () => {
        scrollToTop();
    };
    const listenToScroll = () => {
        let heightToHidden = 250;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > heightToHidden) {
            setisVisible(true);
        } else {
            setisVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
    }, []);
    const tawkMessengerRef = useRef();
    return (
        <>
            {isVisible && (
                <div className="right-2 fixed bottom-40 bg-[#410EAD]	transition-transform duration-500 transform hover:scale-110 cursor-pointer rounded-md p-2">
                    <FaArrowUp
                        size={20}
                        onClick={handleButtonClick}
                        className="text-white"
                    />
                </div>
            )}
            <TawkMessengerReact
                propertyId={process.env.NEXT_PUBLIC_TWAKTO_PROPERTY_ID}
                widgetId={process.env.NEXT_PUBLIC_TWAKTO_WIDGET_ID}
                // widgetId="default"
                useRef={tawkMessengerRef}
            />
        </>
    );
};

export default LiveMessanger;
